.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    //background-color: rgba(#000, 0.9);
    background-color: rgba(18, 104, 150, .9);
    //background-color: rgba(250,250,250,.9);
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;
    transition: all 0.5s ease-in-out;
 
    &.active {
       opacity: 1;
       visibility: visible;

       .modal__content {
          opacity: 1;
          transform: translate(0, 0);
       }
    }
 
    &__area {
       position: absolute;
       width: 100%;
       height: 100%;
       top: 0;
       left: 0;
    }
 
    &__body {
       width: 100%;
       min-height: 100vh;
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       padding: 0;
    }
 
    &__content {
       width: 100%;
       max-width: 1000px;
       background: #fff;
       margin: 0 auto;
       transition: all 0.3s ease-in-out;
       opacity: 0;
       transform: translate(0px, -100%);

       padding: 2rem;
    }
 
    &__close {
       position: absolute;
       right: 1rem;
       top: .5rem;
       z-index: 101;
    }
 }
