/* talent360 */

.tts-application-container {
    position: fixed;
    bottom: 30px;
    right: 30px;

    input {
        font-size: 1rem;
    }
}

.tts-search-widget {
    height: auto !important;
    min-height: 100vh;
    * {
        font-family: 'Noto Sans', Arial, Helvetica, sans-serif !important;
    }

    .tts-search-box {
        box-shadow: none;
        border-bottom: 1px solid rgba(18, 104, 150, 1);

        input {
        }
        .tts-slider {}

        #ttsSearchAreaSearch {
            font-size: 1rem;
        }

        button#ttsSearch {
            color: #fff;
        }
    }

    .tts-result-item {
        margin: 2rem auto;
        min-height: fit-content;
        &:nth-child(even) {
            background-color: #f1f9fe;
        }
        &.tts-hover {
            box-shadow: none;
            border-radius: 0;
            border: 1px solid transparent;

            &::after {
                box-shadow: none;
            }

            &:hover,
            &:focus {
                border-left: 4px solid rgba(18, 104, 150, 1);

                &::after {
                    box-shadow: none;
                }
            }

            .tts-result-item-right {
                >div {
                    font-weight: 500;
                }
            }
            .tts-result-item-company-logo-container {
                @media (max-width:767px) {
                    display: none;
                }
            }
            .tts-result-item-left {
                @media (max-width:767px) {
                    margin-top:0 !important;
                    margin-left: 0 !important;
                }
            }
        }
        .word-break-all {
            word-break: initial;
            hyphens: auto !important;
            line-height: 1.2;
        }
    }
}